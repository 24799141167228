import React, { useCallback, useEffect, useMemo, useState } from "react";
import CONFIG from "../../utils/config";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux-toolkit/slice/modal";
import { Button, Col, Row, Table } from "antd";
import {
  columnAndComponentFunctionReference,
  withKeyDataSet,
} from "../../utils/functions";
import { fetchSalesforceAPICreditData } from "../../redux-toolkit/slice/admin/asyncThunk";
import layoutDataSelector from "../../redux-toolkit/selectors/layoutData";
import { useSelector } from "react-redux";
import teamsDataSelector from "../../redux-toolkit/selectors/teamData";
import { fetchUsers } from "../../redux-toolkit/slice/users/asyncThunks";
import { fetchTeams } from "../../redux-toolkit/slice/teams/asyncThunk";
import {
  deleteTickerFeed,
  fetchTickerFeed,
} from "../../redux-toolkit/slice/tickerFeed/asyncThunk";
import tickerFeedDataSelector from "../../redux-toolkit/selectors/tickerFeedData";

function TickerFeed() {
  const {
    LABELS: { ID },
    TICKER_FEED_LIST_COLUMN,
    MODALS: { TICKER_FEED },
  } = CONFIG;

  const dispatch = useDispatch();

  const { salesforceAPICreditData } = layoutDataSelector(useSelector);
  const { usersList, teamsList } = teamsDataSelector(useSelector);
  const { tickerFeedList } = tickerFeedDataSelector(useSelector);

  const [currentPage, setCurrentPage] = useState(1);

  const handleCreate = () => {
    dispatch(
      openModal({ key: TICKER_FEED.key, props: { usersList, teamsList } })
    );
  };

  const handleEdit = useCallback(
    (tickerFeedData) => {
      dispatch(
        openModal({
          key: TICKER_FEED.key,
          props: { usersList, teamsList, tickerFeedData },
        })
      );
    },
    [usersList, teamsList, TICKER_FEED.key, dispatch]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteTickerFeed({ id }));
    },
    [dispatch]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableColumn = useMemo(
    () =>
      columnAndComponentFunctionReference(TICKER_FEED_LIST_COLUMN, {
        usersList,
        teamsList,
        handleEdit,
        handleDelete,
      }),
    [TICKER_FEED_LIST_COLUMN, usersList, teamsList, handleEdit, handleDelete]
  );

  useEffect(() => {
    !salesforceAPICreditData && dispatch(fetchSalesforceAPICreditData());
  }, [salesforceAPICreditData, dispatch]);

  useEffect(() => {
    !usersList && dispatch(fetchUsers());
  }, [dispatch, usersList]);

  useEffect(() => {
    !teamsList && dispatch(fetchTeams());
  }, [dispatch, teamsList]);

  useEffect(() => {
    dispatch(fetchTickerFeed());
  }, [dispatch]);

  return (
    <div className="userContainer">
      <Row className="filter-head">
        <Col span={12}>
          <h2>TICKER FEED</h2>
        </Col>
        <Col span={12} className="filter">
          <Button className="primary-outline" onClick={handleCreate}>
            Add Ticker
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          className="cmn-table"
          columns={tableColumn}
          dataSource={withKeyDataSet(ID, tickerFeedList)}
          scroll={{ y: "calc(70vh - 55px)" }}
          pagination={{
            currentPage,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
}

export default TickerFeed;
