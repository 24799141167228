import { Col, Form, Input, Row } from "antd";
import React from "react";

const { TextArea } = Input;

function CustomKwipMessage({ kwipObject, setKwipObject, setIsEdited }) {
  const handleTextChange = ({ target: { name, value } }) => {
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex align-items-center">
          Headline
        </Col>
        <Col xs={17} sm={17} md={17} lg={17} xl={17}>
          <Input
            name="headline"
            value={kwipObject.headline}
            maxLength={24}
            placeholder="Enter Headline of maximum length 24"
            onChange={handleTextChange}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex align-items-center">
          Sub Headline
        </Col>
        <Col xs={17} sm={17} md={17} lg={17} xl={17}>
          <Input
            name="subHeadline"
            value={kwipObject.subHeadline}
            maxLength={30}
            placeholder="Enter Sub Headline of maximum length 30"
            onChange={handleTextChange}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-3">
        <Col md={3} lg={3} xl={3} className="d-flex">
          Body
        </Col>
        <Col md={17} lg={17} xl={17}>
          <TextArea
            name="body"
            value={kwipObject.body}
            placeholder="Enter Body of maximum length 250"
            autoSize={{ minRows: 4, maxRows: 5 }}
            maxLength={250}
            onChange={handleTextChange}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default CustomKwipMessage;
