import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import { createKwip, deleteKwip, editKwip, fetchKwips } from "./asyncThunk";

export const kwipSlice = createSlice({
  name: "kwips",
  initialState: INITIAL_STATE.KWIPS,
  reducers: {
    clearKwips: (state) => {
      state.kwipList = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKwips.fulfilled, (state, { payload }) => {
      state.error = false;
      state.kwipList = payload.map((item) => {
        let { kwip_metadata, ...rest } = item;

        let { creditTo, trigger_id } = kwip_metadata ?? {};

        let data = { ...rest };

        if (creditTo && trigger_id) {
          data.creditTo = creditTo;
          data.trigger_id = trigger_id;
        }

        return data;
      });
    });
    builder.addCase(fetchKwips.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(createKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let { kwip_metadata, ...rest } = payload;

      let { creditTo, trigger_id } = kwip_metadata ?? {};

      let data = { ...rest };

      if (creditTo && trigger_id) {
        data.creditTo = creditTo;
        data.trigger_id = trigger_id;
      }

      state.kwipList.push(data);
    });

    builder.addCase(createKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(editKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let { kwip_metadata, ...rest } = payload;

      let { creditTo, trigger_id } = kwip_metadata ?? {};

      let data = { ...rest };

      if (creditTo && trigger_id) {
        data.creditTo = creditTo;
        data.trigger_id = trigger_id;
      }

      let editedIndex = state.kwipList.findIndex(({ id }) => id === payload.id);

      state.kwipList[editedIndex] = data;
    });

    builder.addCase(editKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });

    builder.addCase(deleteKwip.fulfilled, (state, { payload }) => {
      state.error = false;
      let editedIndex = state.kwipList.findIndex(({ id }) => id === payload);
      state.kwipList.splice(editedIndex, 1);
    });

    builder.addCase(deleteKwip.rejected, (state, { error }) => {
      let errorObject = JSON.parse(error.message);
      state.error = errorObject;
    });
  },
});

export const { clearKwips } = kwipSlice.actions;

export default kwipSlice.reducer;
