import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import { errorNotification } from "../../../utils/functions";

export const fetchSalesforceReports = createAsyncThunk(
  "tickerFeeds/fetchSalesforceReports",
  async ({ callback, reportFolderType }) => {
    let {
      API_URLS: { FETCH_SALESFORCE_REPORTS },
    } = CONFIG;

    try {
      let { data } = await getRequest(
        FETCH_SALESFORCE_REPORTS + `?report_type=${reportFolderType}`,
        {
          service: "SALESFORCE",
        }
      );
      return { data, reportFolderType };
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch Salesforce Reports",
      });
    } finally {
      callback();
    }
  }
);

export const fetchReportFields = async (reportId) => {
  let {
    API_URLS: { FETCH_REPORT_FIELDS },
  } = CONFIG;

  try {
    let { data } = await getRequest(
      FETCH_REPORT_FIELDS + `?report_id=${reportId}`,
      {
        service: "SALESFORCE",
      }
    );
    return data;
  } catch ({ response }) {
    errorNotification({
      title: "API Error",
      message: "Salesforce Report Fields are not allowed",
    });
    return undefined;
  }
};

export const createTickerFeed = createAsyncThunk(
  "tickerFeeds/createTickerFeed",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_TICKER_FEED },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(CREATE_TICKER_FEED, payload, {
        service: "TICKER_FEED",
      });
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to create Ticker Feed",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const editTickerFeed = createAsyncThunk(
  "tickerFeeds/editTickerFeed",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { EDIT_TICKER_FEED },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await putRequest(
        EDIT_TICKER_FEED + payload.id + "/",
        payload,
        {
          service: "TICKER_FEED",
        }
      );
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to create Ticker Feed",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const fetchTickerFeed = createAsyncThunk(
  "tickerFeeds/fetchTickerFeed",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_TICKER_FEED },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_TICKER_FEED, {
        service: "TICKER_FEED",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch Salesforce Reports",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const deleteTickerFeed = createAsyncThunk(
  "tickerFeeds/deleteTickerFeed",
  async ({ id }, { dispatch }) => {
    let {
      API_URLS: { DELETE_TICKER_FEED },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_TICKER_FEED + id + "/", {
        service: "TICKER_FEED",
      });
      return id;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch Salesforce Reports",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);
