import { Checkbox, Col, Divider, Form, Row, Select, Tooltip } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  createId,
  extraFiltersForFilterCriteria,
  extraFiltersForValueField,
  filterCreditToOption,
  filterFields,
  filterFieldsForCreditTo,
  filterMetricFields,
  generateMultiSelectTitle,
  getMappingText,
  getNotificationOperation,
  getTypeForMetric,
  isErrorPresent,
  isSubstring,
  metricOptionCreator,
  optionCreator,
  updatedValidationForStepTwo,
} from "../../../../../utils/functions";
import CONFIG from "../../../../../utils/config";
import ValueSelector from "./components/valueSelector";
import Validation from "../../../../../components/validation";
import ErrorMessage from "../../../../../components/validation/components/errorMessage";

function SecondStep({ fields, modalData, setModalData, setIsEdited }) {
  const {
    CREDIT_TYPE,
    NOTIFICATION_OPERATION,
    ERRORS: { REQUIRED },
    LEADERBOARD_CALCULATION_OPTIONS,
    LABELS: {
      VALIDATION: { REQUIRED: REQUIRED_RULE },
    },
  } = CONFIG;

  const { filterOptions, creditTo, object } = modalData;

  const metricFieldRef = useRef(null);

  const [currentFilterOption, setCurrentFilterOption] = useState({});
  const [showError, setShowError] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [reInitialize, setReInitialize] = useState(false);
  const [recordCountOnlyHovered, setRecordCountOnlyHovered] = useState(false);

  const handleCurrentFilterFieldChange = (field) => {
    setCurrentFilterOption({ field });
    setShowError(false);
  };

  const handleValueChange = (value) => {
    setCurrentFilterOption((prev) => ({
      ...prev,
      value: TYPE === "select" ? [...(prev.value ?? []), value] : value,
    }));
    setShowError(false);
  };

  const handleOpertorChange = (operator) => {
    setCurrentFilterOption({
      ...currentFilterOption,
      operator,
      value: undefined,
    });
    setShowError(false);
  };

  const handleAddOperation = (errorObject) => {
    setIsEdited(true);
    setShowError(true);
    if (isErrorPresent(errorObject)) return;
    else {
      setModalData({
        ...modalData,
        filterOptions: [
          ...(modalData.filterOptions ?? []),
          { ...currentFilterOption, data_type: TYPE },
        ],
      });
      setCurrentFilterOption({});
      setReInitialize(true);
      setShowError(false);
    }
  };

  const handleCheckboxSelection = (isChecked, item) => {
    if (isChecked) setSelectedCheckbox((prev) => [...prev, item]);
    else
      setSelectedCheckbox((prev) =>
        prev.filter((prevItem) => prevItem !== item)
      );
  };

  const handleOperationDelete = (id) => {
    setIsEdited(true);
    setModalData({
      ...modalData,
      filterOptions: modalData.filterOptions.filter(
        ({ field }) => !id.includes(field)
      ),
    });

    setSelectedCheckbox((prev) =>
      prev.filter((prevItem) => !id.includes(prevItem))
    );
  };

  const handleMetricFieldValueSelect = (key, { data_type } = {}) => {
    setIsEdited(true);
    setModalData({
      ...modalData,
      metricFieldValue: key,
      type: getTypeForMetric(data_type),
      calculation: key === "record_count_only" ? null : modalData.calculation,
      currency: null,
    });
  };

  const handleCalculationChange = (key) => {
    setModalData({ ...modalData, calculation: key });
    setIsEdited(true);
  };

  const handleCreditToChange = (credit, index) => {
    let newCreditToArray = [...(creditTo ?? [])];

    newCreditToArray[index] = {
      type: CREDIT_TYPE[index]?.value ?? `CREDIT_${index}`,
      credit,
    };

    setModalData((prev) => ({
      ...prev,
      creditTo: newCreditToArray,
    }));
    setIsEdited(true);
  };

  const handleTimeframeValueChange = (value) => {
    setModalData({ ...modalData, timeframeFieldValue: value });
    setIsEdited(true);
  };

  const handleDeselect = (deselectKey) => {
    setCurrentFilterOption((prev) => ({
      ...prev,
      value: prev.value.filter((key) => deselectKey !== key),
    }));
  };

  const removeActiveClassFromList = useCallback(() => {
    let parentDiv = document.querySelectorAll(
      ".rc-virtual-list-holder-inner"
    )[0];

    if (parentDiv) {
      let childElements = parentDiv.children;

      for (let i = 0; i < childElements.length; i++) {
        let childElement = childElements[i];

        if (childElement.classList.contains("ant-select-item-option-active")) {
          childElement.classList.remove("ant-select-item-option-active");
        }
      }
    }
  }, []);

  const VALIDATION_RULES = {
    field: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    value: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
    operator: [{ rule: REQUIRED_RULE, errorMessage: REQUIRED }],
  };

  const {
    OPERATORS,
    TYPE,
    OPTIONS = [],
  } = useMemo(
    () =>
      getNotificationOperation(
        NOTIFICATION_OPERATION,
        currentFilterOption.field,
        fields
      ) ?? { OPERATORS: [], TYPE: null, OPTIONS: [] },
    [NOTIFICATION_OPERATION, currentFilterOption, fields]
  );

  return (
    <Validation
      validationRules={updatedValidationForStepTwo(VALIDATION_RULES, TYPE)}
      values={currentFilterOption}
      reIntialize={reInitialize}
      setReInitialize={setReInitialize}
    >
      {(errorObj) => {
        return (
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Metric Value Field"
                className="metric_value_label"
              />
              <div className="d-flex">
                <Form.Item label="Field Name" className="mb-3">
                  <Select
                    id="metric_field_value"
                    ref={metricFieldRef}
                    className="ml-1"
                    showSearch
                    style={{ width: 300 }}
                    placeholder="Search to Select Metric Field Value"
                    optionFilterProp="children"
                    value={
                      modalData.metricFieldValue === "record_count_only"
                        ? "Record Count Only"
                        : modalData.metricFieldValue
                    }
                    filterOption={(input, option) =>
                      isSubstring(input, option.label)
                    }
                    options={extraFiltersForValueField(
                      metricOptionCreator(filterMetricFields(fields)),
                      object
                    )}
                    dropdownRender={(menu) => {
                      return (
                        <>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            className={`ant-select-item ant-select-item-option ${
                              modalData.metricFieldValue === "record_count_only"
                                ? "ant-select-item-option-selected"
                                : ""
                            } ${
                              recordCountOnlyHovered
                                ? "ant-select-item-option-active"
                                : ""
                            }`}
                            onMouseEnter={() => {
                              removeActiveClassFromList();
                              setRecordCountOnlyHovered(true);
                            }}
                            onMouseLeave={() => {
                              setRecordCountOnlyHovered(false);
                            }}
                            onClick={(e) => {
                              handleMetricFieldValueSelect("record_count_only");
                              metricFieldRef.current.blur();
                            }}
                          >
                            <div className="ant-select-item-option-content">
                              Record Count Only
                            </div>
                          </div>
                        </>
                      );
                    }}
                    onSelect={handleMetricFieldValueSelect}
                  />
                </Form.Item>
                {modalData.metricFieldValue !== "record_count_only" && (
                  <Form.Item label="Calculation" className="ml-4 mb-3">
                    <Select
                      className="ml-1"
                      showSearch
                      style={{ width: 220 }}
                      placeholder="Select Metric Calculation"
                      optionFilterProp="children"
                      value={modalData.calculation}
                      options={LEADERBOARD_CALCULATION_OPTIONS}
                      onSelect={handleCalculationChange}
                    />
                  </Form.Item>
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className="d-flex">
                <div>
                  <Form.Item
                    label="Primary Recognition User Field"
                    className="metric_value_label"
                  />
                  <div className="d-flex">
                    <Form.Item label="Field Name" className="mb-3">
                      <Select
                        placeholder="Select Recognition Field"
                        popupMatchSelectWidth={true}
                        style={{ width: 300 }}
                        value={creditTo?.[0]?.credit}
                        options={optionCreator(
                          filterFieldsForCreditTo(fields),
                          "label",
                          "name",
                          "ID"
                        )}
                        onSelect={(key) => handleCreditToChange(key, 0)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="ml-4">
                  <Form.Item
                    label="Metric Timeframe Field"
                    className="metric_value_label"
                  />
                  <div className="d-flex">
                    <Form.Item label="Field Name" className="mb-3">
                      <Select
                        placeholder="Select Recognition Field"
                        popupMatchSelectWidth={true}
                        style={{ width: 300 }}
                        value={modalData.timeframeFieldValue}
                        options={optionCreator(
                          filterMetricFields(fields, "date"),
                          "label",
                          "name"
                        )}
                        onSelect={handleTimeframeValueChange}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Filter Criteria"
                className="metric_value_label"
              />
              <Form name="form_item_path" layout="vertical">
                <Row gutter={[16, 16]} className="custom-height-100">
                  <Col xs={12} sm={10} md={12} lg={8} xl={8} className="h-100">
                    <Form.Item label="Field" className="mb-0">
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        value={currentFilterOption.field}
                        filterOption={(input, option) =>
                          isSubstring(input, option.label)
                        }
                        options={extraFiltersForFilterCriteria(
                          optionCreator(filterFields(fields), "label", "name"),
                          object
                        )}
                        onSelect={handleCurrentFilterFieldChange}
                      />
                      <ErrorMessage
                        errorObject={errorObj}
                        selector={"field"}
                        controlledShowError={showError}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={10} md={12} lg={8} xl={8} className="h-100">
                    <Form.Item label="Operator" className="mb-0">
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        value={currentFilterOption.operator}
                        filterOption={(input, option) =>
                          isSubstring(input, option.label)
                        }
                        options={optionCreator(OPERATORS)}
                        onSelect={handleOpertorChange}
                      />
                      <ErrorMessage
                        errorObject={errorObj}
                        selector={"operator"}
                        controlledShowError={showError}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={10} md={12} lg={7} xl={7} className="h-100">
                    <Form.Item label="Value" className="mb-0">
                      <ValueSelector
                        type={TYPE}
                        options={OPTIONS}
                        selectorValue={currentFilterOption.value}
                        handleValueChange={handleValueChange}
                        handleDeselect={handleDeselect}
                      />
                      <ErrorMessage
                        errorObject={errorObj}
                        selector={"value"}
                        controlledShowError={showError}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="plus-icon h-100" xl={1}>
                    <Form.Item>
                      <label
                        style={{ height: "55px", display: "inline-block" }}
                      />
                      <PlusCircleFilled
                        onClick={() => handleAddOperation(errorObj)}
                      />
                      <p className="no-errorMessage"></p>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="notification-fields mb-3">
                  <h4>
                    {filterOptions?.length ?? 0} Selected Operations
                    {selectedCheckbox.length ? (
                      <DeleteOutlined
                        className="cursor-pointer"
                        onClick={() => handleOperationDelete(selectedCheckbox)}
                      />
                    ) : null}
                  </h4>
                  <div className="notification-fields-list custom-height-100">
                    {filterOptions?.map((data, index) => {
                      const { fieldText, operatorText, valueText } =
                        getMappingText(data, fields);
                      return (
                        <Row
                          gutter={[16, 16]}
                          key={createId(data, "field", index)}
                        >
                          <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                            <Checkbox
                              checked={selectedCheckbox.includes(data.field)}
                              onChange={({ target: { checked } }) => {
                                handleCheckboxSelection(checked, data.field);
                              }}
                            >
                              <span>{fieldText}</span>
                            </Checkbox>
                          </Col>
                          <Col xs={10} sm={16} md={12} lg={8} xl={8}>
                            <span>{operatorText}</span>
                          </Col>
                          <Col xs={10} sm={16} md={12} lg={7} xl={7}>
                            <span>
                              {Array.isArray(valueText) ? (
                                <Tooltip
                                  title={generateMultiSelectTitle(valueText)}
                                >
                                  <span className="multi-select-box">
                                    {valueText.length} selected values
                                  </span>
                                </Tooltip>
                              ) : (
                                valueText
                              )}
                            </span>
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                            <MinusCircleFilled
                              onClick={() => handleOperationDelete(data.field)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        );
      }}
    </Validation>
  );
}

export default SecondStep;
