import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  editAdmin,
  editSalesforceAPICreditData,
  fetchAdminDetails,
  fetchSalesforceAPICreditData,
  fetchSalesforceOrgDetails,
  passwordStatus,
  sendAdminCode,
  sendAdminCodeForIntegration,
} from "./asyncThunk";
import { setToken } from "../../../utils/functions";
import CONFIG from "../../../utils/config";

export const adminSlice = createSlice({
  name: "admin",
  initialState: INITIAL_STATE.ADMIN,
  reducers: {
    clearAdmin: (state) => {
      state.adminDetails = undefined;
      state.codeFetched = undefined;
      state.passwordStatus = undefined;
      state.error = undefined;
      state.salesforceOrgDetails = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendAdminCode.fulfilled, (state, { payload }) => {
      const { access_token, refresh_token } = payload;
      state.codeFetched = true;
      setToken(CONFIG.TOKEN.ACCESS_TOKEN, access_token);
      setToken(CONFIG.TOKEN.REFRESH_TOKEN, refresh_token);
    });
    builder.addCase(sendAdminCode.rejected, (state, { error }) => {
      state.error = JSON.parse(error.message);
    });

    builder.addCase(
      sendAdminCodeForIntegration.fulfilled,
      (state, { payload }) => {
        const { access_token, refresh_token } = payload || {};
        setToken(CONFIG.TOKEN.ACCESS_TOKEN, access_token);
        setToken(CONFIG.TOKEN.REFRESH_TOKEN, refresh_token);
        state.adminDetails = undefined;
        state.salesforceOrgDetails = undefined;
      }
    );
    builder.addCase(
      sendAdminCodeForIntegration.rejected,
      (state, { error }) => {}
    );

    builder.addCase(
      fetchAdminDetails.fulfilled,
      (state, { payload: { data } }) => {
        state.error = null;
        state.adminDetails = data;
        setToken("adminEmail", data.email);
        setToken("adminId", data.customer_id);
        setToken("kwipo_user_id", data.id);
      }
    );
    builder.addCase(fetchAdminDetails.rejected, (state) => {
      state.error = true;
    });

    builder.addCase(editAdmin.fulfilled, (state, { payload }) => {
      state.error = null;
      state.adminDetails = payload;
    });

    builder.addCase(editAdmin.rejected, (state) => {
      state.error = true;
    });

    builder.addCase(passwordStatus.fulfilled, (state, { payload }) => {
      state.error = null;
      state.passwordStatus = payload;
    });
    builder.addCase(passwordStatus.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(
      fetchSalesforceOrgDetails.fulfilled,
      (state, { payload: { data } }) => {
        state.error = null;
        state.salesforceOrgDetails = data;
      }
    );
    builder.addCase(fetchSalesforceOrgDetails.rejected, (state, { error }) => {
      state.error = true;
    });

    builder.addCase(
      fetchSalesforceAPICreditData.fulfilled,
      (state, { payload }) => {
        state.error = null;
        state.salesforceAPICreditData = payload;
      }
    );
    builder.addCase(
      fetchSalesforceAPICreditData.rejected,
      (state, { error }) => {
        state.error = true;
      }
    );
    builder.addCase(
      editSalesforceAPICreditData.fulfilled,
      (state, { payload }) => {
        state.error = null;
        state.salesforceAPICreditData = payload;
      }
    );
    builder.addCase(
      editSalesforceAPICreditData.rejected,
      (state, { error }) => {
        state.error = true;
      }
    );
  },
});

export const { clearAdmin } = adminSlice.actions;

export default adminSlice.reducer;
