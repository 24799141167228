import { Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSalesforceReports } from "../../../../redux-toolkit/slice/tickerFeed/asyncThunk";
import tickerFeedDataSelector from "../../../../redux-toolkit/selectors/tickerFeedData";
import { useSelector } from "react-redux";

function StepOne({
  tickerFeedObject,
  setTickerFeedObject,
  setIsEdited,
  report_fetch_folder,
}) {
  const dispatch = useDispatch();

  const { salesforceReports } = tickerFeedDataSelector(useSelector);

  const [fetchingReports, setFetchingReports] = useState(false);

  const handleInputChange = ({ target: { value } }) => {
    setTickerFeedObject((prev) => ({ ...prev, name: value }));
    setIsEdited(true);
  };

  const handleSelectChange = (key, value) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({ ...prev, [key]: value }));
  };

  const handleSalesforceReportChange = (value, title) => {
    setTickerFeedObject((prev) => ({
      ...prev,
      title,
      salesforceReport: value,
    }));
  };

  useEffect(() => {
    if (
      !salesforceReports[
        tickerFeedObject.reportFolderType ?? report_fetch_folder
      ]
    ) {
      setFetchingReports(true);
      dispatch(
        fetchSalesforceReports({
          callback: () => setFetchingReports(false),
          reportFolderType:
            tickerFeedObject.reportFolderType ?? report_fetch_folder,
        })
      );
    }
  }, [
    dispatch,
    report_fetch_folder,
    salesforceReports,
    tickerFeedObject.reportFolderType,
  ]);

  const salesforceReportOptions = useMemo(() => {
    return salesforceReports[
      tickerFeedObject.reportFolderType ?? report_fetch_folder
    ]?.map((report) => ({
      label: report.name,
      value: report.id,
    }));
  }, [
    report_fetch_folder,
    salesforceReports,
    tickerFeedObject.reportFolderType,
  ]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row className="mb-3 mt-3" gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Feed Name"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Input
            placeholder="Enter feed name here. . ."
            name="titleName"
            value={tickerFeedObject?.name}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Feed Type"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            style={{ width: "100%" }}
            value={tickerFeedObject?.type}
            placeholder="Select feed type"
            options={[
              { label: "Salesforce Report", value: "salesforce_report" },
            ]}
            onChange={(value) => handleSelectChange("type", value)}
          />
        </Col>
      </Row>
      {tickerFeedObject?.type && (
        <Row gutter={[16, 16]}>
          <Col xs={20} sm={4} md={6} lg={8} xl={12}>
            <Form.Item
              label="Salesforce Report"
              className="mb-0 no-form-item"
              children={null}
              required
            />
            <Select
              loading={fetchingReports}
              options={salesforceReportOptions}
              placeholder="Select Salesforce Report"
              style={{ width: "100%" }}
              value={tickerFeedObject?.salesforceReport}
              onChange={(value, { label }) => {
                handleSalesforceReportChange(value, label);
              }}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
}

export default StepOne;
