import { Col, DatePicker, Form, Radio, Row, Select } from "antd";
import React from "react";
import { hourArray, minutesArray } from "../../../../utils/functions";
import dayjs from "dayjs";

function CustomKwipSchedule({ kwipObject, setKwipObject, setIsEdited }) {
  const handleChange = ({ target: { value } }) => {
    setIsEdited(true);
    setKwipObject((prev) => ({ ...prev, start_type: value }));
  };

  const handleDateChange = (date, key) => {
    setIsEdited(true);
    setKwipObject((prev) => ({ ...prev, [key]: date }));
  };

  const handleTimeChange = (time, key, type) => {
    setIsEdited(true);
    setKwipObject((prev) => ({
      ...prev,
      [key]: { ...(prev[key] ?? {}), [type]: time },
    }));
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <Form.Item
            label="Start"
            className="mb-0 no-form-item customKwipFontLabel mt-2"
            children={null}
            required
          />
          <Row>
            <Radio.Group onChange={handleChange} value={kwipObject.start_type}>
              <Radio className="ml-2 mr-5" value="immediately">
                Immediately
              </Radio>
              <Radio value="future">Future</Radio>
            </Radio.Group>
          </Row>
          {kwipObject.start_type === "future" && (
            <Row className="mt-2">
              <Col xs={10} style={{ marginLeft: "25%" }}>
                <DatePicker
                  value={
                    kwipObject?.start_date
                      ? dayjs(kwipObject?.start_date)
                      : undefined
                  }
                  format="MMMM DD, YYYY"
                  onChange={(date) => {
                    handleDateChange(date, "start_date");
                  }}
                />
              </Col>
              <Col xs={16} className="mt-2" style={{ marginLeft: "12%" }}>
                <Select
                  options={hourArray()}
                  style={{ width: "60px" }}
                  value={kwipObject?.start_time?.hour}
                  onChange={(value) => {
                    handleTimeChange(value, "start_time", "hour");
                  }}
                />
                <Select
                  options={minutesArray()}
                  style={{ width: "60px" }}
                  className="ml-2"
                  value={kwipObject?.start_time?.min}
                  onChange={(value) => {
                    handleTimeChange(value, "start_time", "min");
                  }}
                />
                <Radio.Group
                  value={kwipObject?.start_time?.meridian}
                  onChange={({ target: { value } }) => {
                    handleTimeChange(value, "start_time", "meridian");
                  }}
                >
                  <Radio className="ml-2" value="AM">
                    AM
                  </Radio>
                  <Radio value="PM">PM</Radio>
                </Radio.Group>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={14} sm={14} md={14} lg={14} xl={14}>
          <Form.Item
            label="End"
            className="mb-0 no-form-item customKwipFontLabel mt-2"
            children={null}
            required
          />
          <Row className="mt-1">
            <Col xs={10} style={{ marginLeft: "35px" }}>
              <DatePicker
                value={
                  kwipObject?.end_date ? dayjs(kwipObject?.end_date) : undefined
                }
                format="MMMM DD, YYYY"
                onChange={(date) => {
                  handleDateChange(date, "end_date");
                }}
              />
            </Col>
            <Col xs={16} className="mt-2">
              <Select
                options={hourArray()}
                style={{ width: "60px" }}
                value={kwipObject?.end_time?.hour}
                onChange={(value) => {
                  handleTimeChange(value, "end_time", "hour");
                }}
              />
              <Select
                options={minutesArray()}
                style={{ width: "60px" }}
                className="ml-2"
                value={kwipObject?.end_time?.min}
                onChange={(value) => {
                  handleTimeChange(value, "end_time", "min");
                }}
              />
              <Radio.Group
                value={kwipObject?.end_time?.meridian}
                onChange={({ target: { value } }) => {
                  handleTimeChange(value, "end_time", "meridian");
                }}
              >
                <Radio className="ml-2" value="AM">
                  AM
                </Radio>
                <Radio value="PM">PM</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default CustomKwipSchedule;
