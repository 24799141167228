const initialState = {
  ADMIN: {
    adminDetails: undefined,
    salesforceOrgDetails: undefined,
    passwordStatus: undefined,
    codeFetched: undefined,
    error: undefined,
    salesforceAPICreditData: undefined,
  },
  USERS: {
    usersList: undefined,
    usersMusicList: {},
    error: undefined,
  },
  TEAMS: {
    groups: undefined,
    teamsList: undefined,
    error: undefined,
  },
  LOADING: {
    loading: 0,
  },
  MODAL: {
    key: undefined,
    props: undefined,
  },
  METRICES: {
    metricesList: undefined,
    error: undefined,
  },
  LEADERBOARD: {
    leaderboardList: undefined,
    error: undefined,
  },
  NOTIFICATION: {
    notificationList: undefined,
    objects: undefined,
    fields: undefined,
    error: undefined,
  },
  GROUPS: {
    groupList: undefined,
    fetchedUsers: undefined,
    sync_interval: undefined,
  },
  TEMPLATES: { templates: undefined, error: undefined },
  KWIPS: { kwipList: undefined, error: undefined },
  DASHBOARD_DETAILS: {
    currentFilter: 0,
    kwipLogs: undefined,
    dateFilter: [],
    tickerLogs: undefined,
    details: undefined,
    error: undefined,
  },
  TICKER: {
    tickerList: undefined,
    error: undefined,
  },
  TICKER_FEED: {
    tickerFeedList: undefined,
    salesforceReports: {
      all: undefined,
      kwipo_reports: undefined,
    },
    error: undefined,
  },
};

export default initialState;
