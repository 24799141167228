import { Col, Form, Input, InputNumber, Row, Select, Tooltip } from "antd";
import { PlusCircleFilled, CloseCircleFilled } from "@ant-design/icons";

function StepTwo({
  tickerFeedObject,
  setTickerFeedObject,
  reportColumns,
  setIsEdited,
}) {
  const handleInputChange = ({ target: { value } }) => {
    setTickerFeedObject((prev) => ({ ...prev, name: value }));
    setIsEdited(true);
  };

  const handleSelectChange = (key, value) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({ ...prev, [key]: value }));
  };

  const handleReportColumnChange = (key, value, index) => {
    setTickerFeedObject((prev) => {
      let prevColumns = [...(prev?.columns ?? [])] ?? [];
      prevColumns[index] = { ...prevColumns[index], [key]: value };
      return { ...prev, columns: prevColumns };
    });
    setIsEdited(true);
  };

  const handleReportColumnAction = (index, type) => {
    if (type === "add") {
      setTickerFeedObject((prev) => {
        let prevColumns = [...prev?.columns] ?? [];
        prevColumns.push({ column: "", name: "" });
        return { ...prev, columns: prevColumns };
      });
    } else {
      setTickerFeedObject((prev) => {
        let prevColumns = [...prev?.columns] ?? [];
        prevColumns.splice(index, 1);
        return { ...prev, columns: prevColumns };
      });
    }
  };

  const reportColumnOptions = reportColumns.map(({ field, label }) => ({
    label,
    value: field,
  }));

  const handleMaxRowsChange = (value) => {
    if (value < 1 || value > 25) return;
    setTickerFeedObject((prev) => ({ ...prev, maxRows: value }));
    setIsEdited(true);
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row className="mb-3 mt-3" gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Feed Title"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Input
            placeholder="Enter feed title here. . ."
            name="titleName"
            value={tickerFeedObject?.title}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Ticker Category"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            style={{ width: "100%" }}
            value={tickerFeedObject?.category}
            placeholder="Select Ticker Category"
            options={[
              {
                label: "Salesforce Report Update",
                value: "salesforce_report_update",
              },
            ]}
            onChange={(value) => handleSelectChange("category", value)}
          />
        </Col>
      </Row>
      <Row className="mb-3 mt-3" gutter={[16, 16]}>
        <Col xs={20} sm={4} md={6} lg={8} xl={12}>
          <Form.Item
            label="Report Column (Feed Description)"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Select
            style={{ width: "100%" }}
            placeholder="Select Column from Report"
            options={reportColumnOptions}
            value={tickerFeedObject?.columns?.[0]?.column}
            onChange={(_, { label, value }) => {
              handleReportColumnChange("column", value, 0);
              handleReportColumnChange("name", label, 0);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3 mt-3" gutter={[16, 16]}>
        <Col xs={20} sm={8} md={8} lg={8} xl={8} className="position-relative">
          <Form.Item
            label="Report Column (Feed Value)"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          {tickerFeedObject?.columns?.length === 2 && (
            <Tooltip title="Click to add new Column Value" key="2">
              <PlusCircleFilled
                className="tickerFeedValue"
                onClick={() => {
                  handleReportColumnAction(3, "add");
                }}
              />
            </Tooltip>
          )}
          <Select
            style={{ width: "100%" }}
            placeholder="Select Column from Report"
            options={reportColumnOptions}
            value={tickerFeedObject?.columns?.[1]?.column}
            onChange={(_, { label, value }) => {
              handleReportColumnChange("column", value, 1);
              handleReportColumnChange("name", label, 1);
            }}
          />
        </Col>
        {tickerFeedObject?.columns?.length >= 3 && (
          <Col
            xs={20}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className="position-relative"
          >
            <Form.Item
              label="Report Column (Feed Value)"
              className="mb-0 no-form-item"
              children={null}
            />
            {tickerFeedObject?.columns?.length === 3 && (
              <>
                <Tooltip title="Click to add new Column Value" key="3-1">
                  <PlusCircleFilled
                    className="tickerSecondFeedValue"
                    onClick={() => {
                      handleReportColumnAction(4, "add");
                    }}
                  />
                </Tooltip>
                <Tooltip title="Click to remove this Column Value" key="3-2">
                  <CloseCircleFilled
                    className="tickerFeedValue"
                    onClick={() => {
                      handleReportColumnAction(2, "remove");
                    }}
                  />
                </Tooltip>
              </>
            )}
            <Select
              style={{ width: "100%" }}
              placeholder="Select Column from Report"
              options={reportColumnOptions}
              value={tickerFeedObject?.columns?.[2]?.column}
              onChange={(_, { label, value }) => {
                handleReportColumnChange("column", value, 2);
                handleReportColumnChange("name", label, 2);
              }}
            />
          </Col>
        )}
        {tickerFeedObject?.columns?.length === 4 && (
          <Col
            xs={20}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className="position-relative"
          >
            <Form.Item
              label="Report Column (Feed Value)"
              className="mb-0 no-form-item"
              children={null}
            />
            <Tooltip title="Click to remove this Column Value" key="3-2">
              <CloseCircleFilled
                className="tickerFeedValue"
                onClick={() => {
                  handleReportColumnAction(3, "remove");
                }}
              />
            </Tooltip>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Column from Report"
              options={reportColumnOptions}
              value={tickerFeedObject?.columns?.[3]?.column}
              onChange={(_, { label, value }) => {
                handleReportColumnChange("column", value, 3);
                handleReportColumnChange("name", label, 3);
              }}
            />
          </Col>
        )}
      </Row>
      <Row className="mt-3" gutter={[16, 16]}>
        <div className="ml-2 d-flex align-items-center">
          <span style={{ fontWeight: "600" }}>
            Number of Rows to Display (max 25)
          </span>
          <InputNumber
            type="number"
            size="small"
            min={1}
            max={25}
            value={tickerFeedObject?.maxRows}
            className="ml-2 mr-2"
            onChange={handleMaxRowsChange}
          />
        </div>
      </Row>
    </Form>
  );
}

export default StepTwo;
