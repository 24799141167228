import { createAsyncThunk } from "@reduxjs/toolkit";

import CONFIG from "../../../utils/config";
import { getRequest, postRequest } from "../../../api/baseAPI";
import { beginAPICall, endAPICall } from "../loading";
import {
  errorNotification,
  setSessionTimeout,
  setToken,
  successNotification,
} from "../../../utils/functions";

export const sendAdminCode = createAsyncThunk(
  "auth/sendAdminCode",
  async (token) => {
    let reqData = new FormData();
    reqData.append("code", token);
    reqData.append("login_type", "admin");

    try {
      let { data } = await postRequest(
        CONFIG.API_URLS.SEND_ADMIN_TOKEN_API,
        reqData,
        {
          service: "SALESFORCE",
        }
      );
      return data;
    } catch ({ response }) {
      const { data } = response;
      throw new Error(JSON.stringify(data));
    }
  }
);

export const sendAdminCodeForIntegration = createAsyncThunk(
  "auth/sendAdminCodeForIntegration",
  async (
    { code, custom_admin_email, custom_admin_id, reAuthError = () => {} },
    { dispatch }
  ) => {
    let reqData = new FormData();
    reqData.append("code", code);
    reqData.append("connection_email", encodeURIComponent(custom_admin_email));
    reqData.append("customer_id", custom_admin_id);
    reqData.append("login_type", "admin");

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        CONFIG.API_URLS.SEND_ADMIN_TOKEN_API,
        reqData,
        {
          service: "SALESFORCE",
        }
      );
      return data;
    } catch ({ response: { status } }) {
      if (status === 406) reAuthError();
      else
        errorNotification({
          title: "Authentication Failed",
          message: "Admin authentication failed with Salesforce",
        });
      throw new Error("");
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchAdminDetails = createAsyncThunk(
  "auth/fetchAdminDetails",
  async (_, { dispatch }) => {
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(CONFIG.API_URLS.FETCH_ADMIN_DETAILS, {
        service: "ACCOUNT",
      });
      setSessionTimeout(data.data.refresh_token_expiry_hours);
      return data;
    } catch ({ response }) {
      const { data } = response;
      setToken(CONFIG.TOKEN.ACCESS_TOKEN, "");
      setToken(CONFIG.TOKEN.REFRESH_TOKEN, "");
      throw new Error(JSON.stringify(data));
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const signupUser = createAsyncThunk(
  "userDetails/signupUser",
  async (
    { email, password, name, username, callback = () => {} },
    { dispatch }
  ) => {
    const {
      API_URLS: { SIGN_UP_ADMIN },
    } = CONFIG;
    try {
      dispatch(beginAPICall());
      let data = new FormData();
      data.append("email", email);
      data.append("password", password);
      data.append("name", name);
      data.append("username", username);
      await postRequest(SIGN_UP_ADMIN, data);
    } catch ({ response: { data } }) {
      errorNotification(data);
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const loginWithEmail = createAsyncThunk(
  "userDetails/loginWithEmail",
  async ({ email, password, callback = () => {} }, { dispatch }) => {
    const {
      API_URLS: { LOGIN_ADMIN_WITH_EMAIL },
      TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let data = {
        email,
        password,
        grant_type: "password",
        login_type: "admin",
      };
      const {
        data: { access_token, refresh_token },
      } = await postRequest(LOGIN_ADMIN_WITH_EMAIL, data, {
        service: "ACCOUNT",
      });
      setToken(ACCESS_TOKEN, access_token);
      setToken(REFRESH_TOKEN, refresh_token);
      callback();
    } catch ({ response: { data } }) {
      errorNotification(data);
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const setPassword = createAsyncThunk(
  "userDetails/setPassword",
  async ({ payload: { id, ...payload }, callback }, { dispatch }) => {
    const {
      API_URLS: { SET_PASSWORD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await postRequest(SET_PASSWORD + id + "/password/", payload, {
        service: "ACCOUNT",
      });
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to set password",
      });
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const passwordStatus = createAsyncThunk(
  "userDetails/passwordStatus",
  async ({ payload: { token, userId } }, { dispatch }) => {
    const {
      API_URLS: { CHECK_PASSWORD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(CHECK_PASSWORD + userId + "/password/", {
        service: "ACCOUNT",
        params: { token },
      });
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch password status",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "userDetails/forgetPassword",
  async ({ payload }, { dispatch }) => {
    const {
      API_URLS: { FORGET_PASSWORD },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await postRequest(FORGET_PASSWORD, payload, { service: "ACCOUNT" });
      successNotification({
        title: "Email Sent",
        message: "Email has been sent to reset the password",
      });
    } catch ({ response }) {
      let {
        data: { message },
      } = response;

      errorNotification({
        title: "API Error",
        message,
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const cloneCustomer = createAsyncThunk(
  "customer/cloneCustomer",
  async ({ customer_token, callback }, { dispatch }) => {
    let {
      API_URLS: { CLONE_CUSTOMER },
      TOKEN: { ACCESS_TOKEN, REFRESH_TOKEN },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { access_token, refresh_token },
      } = await getRequest(CLONE_CUSTOMER, {
        service: "ACCOUNT",
        params: { token: customer_token },
      });
      setToken(ACCESS_TOKEN, access_token);
      setToken(REFRESH_TOKEN, refresh_token);
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to clone customer",
      });
    } finally {
      dispatch(endAPICall());
      callback();
    }
  }
);

export const editAdmin = createAsyncThunk(
  "customer/editAdmin",
  async (
    { payload: { id, ...payload }, callback = () => {} },
    { dispatch }
  ) => {
    let {
      API_URLS: { FETCH_ADMIN_DETAILS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(FETCH_ADMIN_DETAILS, payload, {
        service: "ACCOUNT",
      });
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to edit admin details",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const fetchSalesforceOrgDetails = createAsyncThunk(
  "auth/fetchSalesforceOrgDetails",
  async (_, { dispatch }) => {
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(
        CONFIG.API_URLS.FETCH_SALESFORCE_ORG_DETAILS,
        {
          service: "SALESFORCE",
        }
      );
      return data;
    } catch ({ response }) {
      throw new Error("");
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const fetchSalesforceAPICreditData = createAsyncThunk(
  "auth/fetchSalesforceAPICreditData",
  async (_, { dispatch }) => {
    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(
        CONFIG.API_URLS.FETCH_SALESFORCE_API_CREDIT_DATA,
        {
          service: "ACCOUNT",
        }
      );
      return data;
    } catch ({ response }) {
      throw new Error("");
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const editSalesforceAPICreditData = createAsyncThunk(
  "admin/editSalesforceAPICreditData",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { EDIT_SALESFORCE_API_CREDIT_DATA },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await postRequest(
        EDIT_SALESFORCE_API_CREDIT_DATA,
        payload,
        {
          service: "ACCOUNT",
        }
      );
      return data;
    } catch ({ response }) {
      errorNotification({
        title: "API Error",
        message: "Failed to edit admin details",
      });
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const openStripeAccountPortal = async (dispatch, payload) => {
  const {
    API_URLS: { OPEN_STRIPE_ACCOUNT_PORTAL },
  } = CONFIG;

  try {
    dispatch(beginAPICall());
    const {
      data: {
        data: { url },
      },
    } = await postRequest(OPEN_STRIPE_ACCOUNT_PORTAL, payload, {
      service: "ACCOUNT",
    });
    window.location.href = url;
  } catch (err) {
    errorNotification({
      title: "API Error",
      message: "Failed to open stripe account portal",
    });
  } finally {
    dispatch(endAPICall());
  }
};
