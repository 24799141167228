import { createSlice } from "@reduxjs/toolkit";

import INITIAL_STATE from "../../initialState";
import {
  createTickerFeed,
  deleteTickerFeed,
  editTickerFeed,
  fetchSalesforceReports,
  fetchTickerFeed,
} from "./asyncThunk";

export const tickerFeedSlice = createSlice({
  name: "tickerFeed",
  initialState: INITIAL_STATE.TICKER_FEED,
  reducers: {
    clearTickerFeed: (state) => {
      state.salesforceReports = {
        all: undefined,
        kwipo_reports: undefined,
      };
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesforceReports.fulfilled, (state, { payload }) => {
      let { data, reportFolderType } = payload;
      state.salesforceReports[reportFolderType] = data;
    });
    builder.addCase(fetchSalesforceReports.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(fetchTickerFeed.fulfilled, (state, { payload }) => {
      state.tickerFeedList = payload;
    });
    builder.addCase(fetchTickerFeed.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(createTickerFeed.fulfilled, (state, { payload }) => {
      state.tickerFeedList.push(payload);
    });
    builder.addCase(createTickerFeed.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(editTickerFeed.fulfilled, (state, { payload }) => {
      let index = state.tickerFeedList.findIndex(({ id }) => id === payload.id);
      state.tickerFeedList[index] = payload;
    });
    builder.addCase(editTickerFeed.rejected, (state, { error }) => {
      state.error = true;
    });
    builder.addCase(deleteTickerFeed.fulfilled, (state, { payload }) => {
      state.tickerFeedList = state.tickerFeedList.filter(
        ({ id }) => id !== payload
      );
    });
    builder.addCase(deleteTickerFeed.rejected, (state, { error }) => {
      state.error = true;
    });
  },
});

export const { clearTickerFeed } = tickerFeedSlice.actions;

export default tickerFeedSlice.reducer;
