import { Avatar, Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import {
  firstLetter,
  getDetailsForParticipant,
  groupOptions,
  optionCreator,
} from "../../../../utils/functions";

function StepThree({
  usersList,
  teamsList,
  setIsEdited,
  tickerFeedObject,
  setTickerFeedObject,
}) {
  const [selectedRecipent, setSelectedRecipent] = useState({});
  const [allUsersSelected, setAllUsersSelected] = useState(false);

  const handleAddRecipent = () => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({
      ...prev,
      participants: [...(prev.participants ?? []), selectedRecipent],
    }));
    setSelectedRecipent({});
  };

  const handleDeselect = (key) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({
      ...prev,
      participants:
        prev?.participants?.filter((item) => item.key !== key) ?? [],
    }));
  };

  const handleRecipentSelect = (_, { key, type }) => {
    setSelectedRecipent({ key, type });
  };

  const filteredUsersList = useMemo(() => {
    return (
      usersList?.filter(({ id: userId }) => {
        return (
          !tickerFeedObject?.creditTo?.some(({ id }) => userId === id) ?? false
        );
      }) ?? []
    );
  }, [usersList, tickerFeedObject]);

  const isCheckboxIndeterminate = useMemo(() => {
    const { participants } = tickerFeedObject;
    let selectedUsers = participants?.filter(
      ({ type, isDeletable }) => type === "user" && (isDeletable ?? true)
    );
    return (
      selectedUsers?.length > 0 &&
      selectedUsers?.length < filteredUsersList?.length
    );
  }, [tickerFeedObject, filteredUsersList]);

  useEffect(() => {
    if (isCheckboxIndeterminate) setAllUsersSelected(false);
  }, [isCheckboxIndeterminate]);

  useEffect(() => {
    const { participants } = tickerFeedObject;
    let userMembers =
      participants?.filter(
        ({ type, isDeletable }) => type === "user" && (isDeletable ?? true)
      ) ?? [];

    if (userMembers?.length === filteredUsersList?.length)
      setAllUsersSelected(true);
    else setAllUsersSelected(false);
  }, [tickerFeedObject, filteredUsersList]);

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
          <Form.Item
            label="Recipients"
            className="mb-0 no-form-item"
            children={null}
            required
          />
          <Row gutter={[16, 16]} className="mb-2">
            <Col xl={24}>
              <Select
                showSearch
                placeholder="Select Users or Teams"
                popupMatchSelectWidth={true}
                style={{ width: "100%" }}
                value={selectedRecipent.key}
                options={groupOptions(
                  [
                    { label: "Users", key: "user" },
                    { label: "Teams", key: "team" },
                  ],
                  [
                    optionCreator(usersList, "name", "id"),
                    optionCreator(teamsList, "name", "id"),
                  ],
                  [
                    ...(tickerFeedObject?.creditTo ?? []),
                    ...(tickerFeedObject?.participants ?? []),
                  ]
                )}
                onSelect={handleRecipentSelect}
              />
            </Col>
            <Col className="plus-icon mb-2" sm={4} md={4} xl={2}>
              <Button className="primary-solid" onClick={handleAddRecipent}>
                Add
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <div className="credit-list-scroll kwip-credit-scroll">
            {tickerFeedObject?.participants?.map(
              ({ key, type, isDeletable }) => {
                let { displayName, profile_photo } = getDetailsForParticipant(
                  key,
                  type,
                  usersList,
                  teamsList
                );
                return (
                  <div className="credit-list creditTo" key={key}>
                    <Avatar
                      size={30}
                      src={profile_photo}
                      children={profile_photo ? null : firstLetter(displayName)}
                    />
                    <span className="creditTo_field">{displayName}</span>
                    <span className="close">
                      {(isDeletable ?? true) && (
                        <MinusCircleFilled
                          onClick={() => handleDeselect(key)}
                        />
                      )}
                    </span>
                  </div>
                );
              }
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default StepThree;
