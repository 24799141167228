import { Col, Form, Row, Select } from "antd";
import React from "react";

function StepFive({ kwipObject, setKwipObject, setIsEdited }) {
  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xs={14} sm={14} md={14} lg={14} xl={14}>
          <Form.Item
            label="Notification Type"
            className="mb-0 no-form-item mt-2"
            children={null}
            required
          />
          <Select
            showSearch
            placeholder="Select Type of Notification"
            popupMatchSelectWidth={true}
            style={{ width: "100%" }}
            value={kwipObject.is_minimized}
            options={[
              { label: "Minimized", value: true },
              { label: "Expanded", value: false },
            ]}
            onSelect={(value) => {
              setIsEdited(true);
              setKwipObject({ ...kwipObject, is_minimized: value });
            }}
          />
        </Col>
        <Col xs={14} sm={14} md={14} lg={14} xl={14}>
          <Form.Item
            label="Notification Window Close Interval"
            className="mb-0 no-form-item mt-2"
            children={null}
            required
          />
          <Select
            showSearch
            placeholder="Select Interval to close Notification"
            popupMatchSelectWidth={true}
            style={{ width: "100%" }}
            value={kwipObject.close_interval}
            options={[
              { label: "1 minute", value: 1 * 60 * 1000 },
              { label: "3 minutes", value: 3 * 60 * 1000 },
              { label: "5 minutes", value: 5 * 60 * 1000 },
            ]}
            onSelect={(value) => {
              setIsEdited(true);
              setKwipObject({ ...kwipObject, close_interval: value });
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default StepFive;
