import { Modal, Steps } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux-toolkit/slice/modal";
import StepButton from "./components/stepButton";
import CONFIG from "../../../utils/config";
import {
  modifiedTickerFeedData,
  tickerFeedPayload,
} from "../../../utils/functions";
import layoutDataSelector from "../../../redux-toolkit/selectors/layoutData";
import {
  createTickerFeed,
  editTickerFeed,
  fetchReportFields,
} from "../../../redux-toolkit/slice/tickerFeed/asyncThunk";

function TickerFeedModal({ tickerFeedData, usersList, teamsList }) {
  const { TICKER_FEED_STEPS } = CONFIG;

  const dispatch = useDispatch();

  const { salesforceAPICreditData } = layoutDataSelector(useSelector);

  const { report_fetch_folder } = useMemo(
    () => salesforceAPICreditData || {},
    [salesforceAPICreditData]
  );

  const [currentStep, setCurrent] = useState(0);
  const [reportColumns, setReportColumns] = useState([]);

  const [tickerFeedObject, setTickerFeedObject] = useState(
    tickerFeedData ? modifiedTickerFeedData(tickerFeedData) : {}
  );

  const [isEdited, setIsEdited] = useState(false);
  const [isStepLoading, setStepLoading] = useState(false);

  const handleStepOne = async () => {
    setStepLoading(true);
    let data = await fetchReportFields(tickerFeedObject.salesforceReport);
    setStepLoading(false);
    if (data) {
      setCurrent(currentStep + 1);
      setReportColumns(data);
    }
  };

  const handleSave = () => {
    let payload = tickerFeedPayload({
      ...tickerFeedObject,
      reportFolderType: report_fetch_folder,
    });

    if (tickerFeedObject.id)
      isEdited
        ? dispatch(editTickerFeed({ payload, callback: handleCloseModal }))
        : handleCloseModal();
    else dispatch(createTickerFeed({ payload, callback: handleCloseModal }));
  };

  const next = () => {
    switch (currentStep) {
      case 0: {
        handleStepOne();
        break;
      }
      case 3:
        return handleSave();
      default:
        setCurrent(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      title={tickerFeedData ? tickerFeedData.name : "Create Ticker Feed"}
      centered
      open={true}
      onOk={() => {}}
      onCancel={handleCloseModal}
      width={900}
      footer={
        <StepButton
          currentStep={currentStep}
          isStepLoading={isStepLoading}
          tickerFeedObject={tickerFeedObject}
          prev={prev}
          next={next}
          handleCancel={handleCloseModal}
        />
      }
    >
      <Steps
        current={currentStep}
        percent={60}
        labelPlacement="vertical"
        items={TICKER_FEED_STEPS({})}
      />
      <div className="steps-content">
        {
          TICKER_FEED_STEPS({
            usersList,
            teamsList,
            setIsEdited,
            reportColumns,
            tickerFeedObject,
            report_fetch_folder,
            setTickerFeedObject,
          })[currentStep]?.content
        }
      </div>
      <div className="mb-3" />
    </Modal>
  );
}

export default TickerFeedModal;
