import { combineReducers } from "@reduxjs/toolkit";

import adminReducer from "./admin";
import userReducer from "./users";
import loadingReducer from "./loading";
import teamsReducer from "./teams";
import modalReducer from "./modal";
import notificationReducer from "./notification";
import groupsReducer from "./groups";
import templateReducer from "./template";
import kwipReducer from "./kwips";
import dashboardReducer from "./dashboard";
import tickerReducer from "./ticker";
import metricesReducer from "./metrices";
import leaderboardReducer from "./leaderboard";
import tickerFeedReducer from "./tickerFeed";

export default combineReducers({
  userReducer,
  adminReducer,
  teamsReducer,
  modalReducer,
  groupsReducer,
  loadingReducer,
  notificationReducer,
  templateReducer,
  kwipReducer,
  dashboardReducer,
  tickerReducer,
  metricesReducer,
  tickerFeedReducer,
  leaderboardReducer,
});
