import { Col, Row } from "antd";
import React, { useRef } from "react";
import macOS from "../../assets/images/MacOS.png";
import windows from "../../assets/images/windows.png";

import { RightOutlined } from "@ant-design/icons";

function Applications() {
  const macDownloadLink = useRef(null);
  const windowDownloadLink = useRef(null);

  const handleMacOSDownload = () => {
    if (macDownloadLink.current) macDownloadLink.current.click();
  };

  const handleWindowDownload = () => {
    if (windowDownloadLink.current) windowDownloadLink.current.click();
  };

  return (
    <div className="userContainer">
      <Row className="filter-head mb-2">
        <Col span={12}>
          <h2>APPLICATIONS</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <div className="horizontal-line" />
        <div className="d-flex align-items-center mt-1 mb-1 w-100">
          <img src={macOS} alt="macOS" className="h-100 mix-blend-mode" />
          <div className="ml-3 d-flex w-100 align-items-center justify-content-between">
            <div>
              <h2>Macintosh</h2>
              <p>Download the Kwipo app for MacOS</p>
            </div>
            <div
              className="configure cursor-pointer"
              onClick={handleMacOSDownload}
            >
              <u>Download</u> <RightOutlined />
              <a
                href={process.env.REACT_APP_MAC_BUILD_URL}
                download
                ref={macDownloadLink}
                style={{ display: "none" }}
              >
                Download
              </a>
            </div>
          </div>
        </div>
        <div className="horizontal-line mt-1" />
      </Row>
      <Row className="mt-3">
        <div className="horizontal-line" />
        <div className="d-flex align-items-center mt-1 mb-1 w-100">
          <img src={windows} alt="windows" className="h-100 mix-blend-mode" />
          <div className="ml-3 d-flex w-100 align-items-center justify-content-between">
            <div>
              <h2>Windows</h2>
              <p>Download the Kwipo app for Windows</p>
            </div>
            <div
              className="configure cursor-pointer"
              onClick={handleWindowDownload}
            >
              <u>Download</u> <RightOutlined />
              <a
                href={process.env.REACT_APP_WINDOW_BUILD_URL}
                download
                ref={windowDownloadLink}
                style={{ display: "none" }}
              >
                Download
              </a>
            </div>
          </div>
        </div>
        <div className="horizontal-line mt-1" />
      </Row>
    </div>
  );
}

export default Applications;
