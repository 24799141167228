import {
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import { hourArray, minutesArray } from "../../../../utils/functions";
import dayjs from "dayjs";
import CONFIG from "../../../../utils/config";

function CustomKwipSchedule({
  tickerFeedObject,
  setTickerFeedObject,
  setIsEdited,
}) {
  const { TICKER_CYCLE, TICKER_REPEAT_CYCLE } = CONFIG;

  const handleRadioChange = ({ target: { value } }) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({ ...prev, start_type: value }));
  };

  const handleChange = (date, key) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({ ...prev, [key]: date }));
  };

  const handleTimeChange = (time, key, type) => {
    setIsEdited(true);
    setTickerFeedObject((prev) => ({
      ...prev,
      [key]: { ...(prev[key] ?? {}), [type]: time },
    }));
  };

  return (
    <Form name="form_item_path" layout="vertical" onFinish={() => {}}>
      <Row gutter={[16, 16]}>
        <Col xl={12}>
          <Form.Item
            label="Ticker Cycles"
            className="mb-0 no-form-item customKwipFontLabel mt-2"
            children={null}
            required
          />
          <Row>
            <Col
              xl={14}
              className="mt-1 d-flex flex-column"
              style={{ padding: "0 12px" }}
            >
              <Select
                options={TICKER_CYCLE}
                value={tickerFeedObject?.repeat_frequency}
                onChange={(value) => {
                  handleChange(value, "repeat_frequency");
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          <Form.Item
            label="Repeat Cycle"
            className="mb-0 no-form-item customKwipFontLabel mt-2"
            children={null}
            required
          />
          <Row>
            <Col
              xl={14}
              className="mt-1 d-flex flex-column"
              style={{ padding: "0 12px" }}
            >
              <Select
                options={TICKER_REPEAT_CYCLE}
                value={tickerFeedObject?.repeat_interval}
                onChange={(value) => {
                  handleChange(value, "repeat_interval");
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xl={14}>
          <Form.Item
            label="Ticker Start Time"
            className="mb-0 no-form-item customKwipFontLabel customKwipFontLabel"
            children={null}
            required
          />
          <Row>
            <Col xl={8} className="d-flex">
              <Radio.Group
                onChange={handleRadioChange}
                value={tickerFeedObject.start_type}
              >
                <Space
                  direction="vertical"
                  style={{ marginLeft: "35px", marginTop: "10px" }}
                >
                  <Radio value="immediately">Immediately</Radio>
                  <Radio value="future" className="d-flex mt-2">
                    Future
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
            <Col
              xl={14}
              className="mt-1 d-flex flex-column"
              style={{ padding: "0 12px" }}
            >
              {tickerFeedObject.start_type === "future" && (
                <>
                  <DatePicker
                    value={
                      tickerFeedObject?.start_date
                        ? dayjs(tickerFeedObject?.start_date)
                        : undefined
                    }
                    format="MMMM DD, YYYY"
                    onChange={(date) => {
                      handleChange(date, "start_date");
                    }}
                  />
                  <div className="mt-1 d-flex w-100">
                    <Select
                      options={hourArray()}
                      style={{ width: "60px" }}
                      value={tickerFeedObject?.start_time?.hour}
                      onChange={(value) => {
                        handleTimeChange(value, "start_time", "hour");
                      }}
                    />
                    <Select
                      options={minutesArray()}
                      style={{ width: "60px" }}
                      className="ml-2"
                      value={tickerFeedObject?.start_time?.min}
                      onChange={(value) => {
                        handleTimeChange(value, "start_time", "min");
                      }}
                    />
                    <Radio.Group
                      value={tickerFeedObject?.start_time?.meridian}
                      onChange={({ target: { value } }) => {
                        handleTimeChange(value, "start_time", "meridian");
                      }}
                      className=" d-flex ml-2 align-items-center"
                    >
                      <Radio value="AM">AM</Radio>
                      <Radio value="PM">PM</Radio>
                    </Radio.Group>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col xl={10}>
          <Form.Item
            label="Ticker End Time"
            className="mb-0 no-form-item customKwipFontLabel customKwipFontLabel"
            children={null}
            required
          />
          <Row className="mt-1" style={{ marginLeft: "35px" }}>
            <Col xs={20} className="d-flex flex-column">
              <DatePicker
                value={
                  tickerFeedObject?.end_date
                    ? dayjs(tickerFeedObject?.end_date)
                    : undefined
                }
                format="MMMM DD, YYYY"
                onChange={(date) => {
                  handleChange(date, "end_date");
                }}
              />
            </Col>
            <Col xs={20} className="mt-1">
              <Select
                options={hourArray()}
                style={{ width: "60px" }}
                value={tickerFeedObject?.end_time?.hour}
                onChange={(value) => {
                  handleTimeChange(value, "end_time", "hour");
                }}
              />
              <Select
                options={minutesArray()}
                style={{ width: "60px" }}
                className="ml-2"
                value={tickerFeedObject?.end_time?.min}
                onChange={(value) => {
                  handleTimeChange(value, "end_time", "min");
                }}
              />
              <Radio.Group
                value={tickerFeedObject?.end_time?.meridian}
                onChange={({ target: { value } }) => {
                  handleTimeChange(value, "end_time", "meridian");
                }}
              >
                <Radio className="ml-2" value="AM">
                  AM
                </Radio>
                <Radio value="PM">PM</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default CustomKwipSchedule;
