import { createAsyncThunk } from "@reduxjs/toolkit";
import CONFIG from "../../../utils/config";
import { beginAPICall, endAPICall } from "../loading";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../api/baseAPI";
import { errorNotification } from "../../../utils/functions";
import { closeModal } from "../modal";

export const fetchKwips = createAsyncThunk(
  "kwips/fetchKwips",
  async (_, { dispatch }) => {
    let {
      API_URLS: { FETCH_KWIPS },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let { data } = await getRequest(FETCH_KWIPS, { service: "KWIPS" });
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to fetch kwips",
      });
    } finally {
      dispatch(endAPICall());
    }
  }
);

export const createKwip = createAsyncThunk(
  "kwips/createKwip",
  async ({ payload, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { CREATE_KWIP },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      let {
        data: { data },
      } = await postRequest(CREATE_KWIP, payload, {
        service: "KWIPS",
      });
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to create kwip",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const editKwip = createAsyncThunk(
  "kwips/editKwip",
  async (
    { payload: { id, ...payload }, callback = () => {} },
    { dispatch }
  ) => {
    let {
      API_URLS: { EDIT_KWIP },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      const { data } = await putRequest(
        EDIT_KWIP + id + "/",
        { ...payload, id },
        {
          service: "KWIPS",
        }
      );
      return data;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to edit kwip",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
    }
  }
);

export const deleteKwip = createAsyncThunk(
  "kwips/deleteKwip",
  async ({ payload: { id }, callback = () => {} }, { dispatch }) => {
    let {
      API_URLS: { DELETE_KWIP },
    } = CONFIG;

    try {
      dispatch(beginAPICall());
      await deleteRequest(DELETE_KWIP + id + "/", { service: "KWIPS" });
      return id;
    } catch (err) {
      errorNotification({
        title: "API Error",
        message: "Failed to delete kwip",
      });
      throw new Error();
    } finally {
      callback();
      dispatch(endAPICall());
      dispatch(closeModal());
    }
  }
);
